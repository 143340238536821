/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BusinessCard from './components/BusinessCard';
import Resume from './components/Resume';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BusinessCard />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </Router>
  );
};

export default App;