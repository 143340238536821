/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Mail } from 'lucide-react';
import { SiGithub, SiLinkedin } from 'react-icons/si';

const BusinessCard = () => {
  const socialLinks = [
    { icon: SiGithub, label: 'GitHub', href: 'https://github.com/richmcninch' },
    { icon: SiLinkedin, label: 'LinkedIn', href: 'https://www.linkedin.com/in/richmcninch/' },
  ];



  return (
    <div className="min-h-screen flex flex-col items-center justify-center transition-colors duration-300 
      bg-gray-900">
      <div className="max-w-lg w-full rounded-lg shadow-lg p-8 m-4 transition-colors duration-300 
        bg-gray-800">    

        <h1 className="text-4xl font-bold mb-6 text-center transition-colors duration-300 text-white">
          Rich McNinch
        </h1>
        
        <p className="text-lg mb-8 text-center transition-colors duration-300 text-gray-300">
          Keep This Coupon
        </p>
        
        {/* Contact and Social Links */}
        <div className="flex flex-wrap items-center justify-center gap-6 mb-4">
          <a 
            href="mailto:rich@richmcninch.com"
            className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors duration-200 font-medium"
          >
            <Mail className="w-5 h-5" />
            rich@richmcninch.com
          </a>
          
          <div className="flex gap-4">
            {socialLinks.map((social) => (
              <a
                key={social.label}
                href={social.href}
                className="transition-colors duration-200 text-gray-300 hover:text-blue-400"
                aria-label={social.label}>
                <social.icon className="w-6 h-6" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;